<template>
  <div
    :style="{
      'background-color': 'white',

      border: '2px solid ' + $vuetify.theme.themes.light.secondary,
    }"
    elevation="2"
  >
    <div
      style="display: flex; flex-orientation: row"
      v-if="introductionTitle || introductionText"
    >
      <div style="padding: 15px; width: 70%">
        <h1>
          {{ introductionTitle }}
        </h1>
        <div v-html="introductionText"></div>
      </div>
      <div
        style="
          width: 30%;
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        "
      >
        <div v-if="true" style="width: 50%; padding: 15px">
          <img src="logos/customer_logo.png" style="width: 100%" />
        </div>
        <div v-if="true" style="width: 50%; padding: 15px">
          <img src="cleverroute-logo-green.png" style="width: 100%" />
        </div>
      </div>
    </div>
    <div style="padding: 15px" :style="`background-color: ${containerColor}`">
      <v-divider
        v-if="
          pollMonthsInUniversityMax !== undefined ||
          pollMonthsInOrganizationMax !== undefined ||
          pollWorkingDays !== undefined ||
          pollHomeofficeDays !== undefined
        "
        style="margin-top: 10px; margin-bottom: 10px"
        orientation="horizontal"
      ></v-divider>

      <h3>{{ title }}</h3>

      <!-- <v-card-text> -->
      <!-- <v-row>
            <h3>Umfrage</h3>
        </v-row> -->
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div
            v-for="(myText, i) in introductionSteps"
            :key="i"
            v-html="myText"
          ></div>
          <!-- -->
        </v-col>
      </v-row>
      <v-row v-if="pollMonthsInUniversity !== undefined">
        <v-col
          v-if="pollMonthsInUniversity !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: flex-end"
          >
            <div class="flex-box-center-items-horizontal">
              <div style="margin: 5px">Monate an der Hochschule 2024</div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span
                  >Pass hier falls notwendig die Zahl der Monate an, die du im
                  aktuellen Jahr an der Hochschule gewesen warst.
                </span>
              </v-tooltip>
              <input
                :style="colorOfInputElementsStyle"
                style="padding-left: 5px; width: 40px"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollMonthsInUniversity,
                    $event.target.value
                  )
                "
                :value="pollMonthsInUniversity"
              />
              <span class="flex-box-center-items-vertical"> / 12</span>
            </div>
            <span v-if="!isPollMonthsInOrganizationValid()" style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          pollMonthsInOrganization !== undefined ||
          pollWorkingDays !== undefined ||
          pollHomeofficeDays !== undefined
        "
      >
        <v-col
          v-if="pollMonthsInOrganization !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: center"
          >
            <div class="flex-box-center-items-horizontal">
              <div style="margin: 5px">Monate in Organisation 2024</div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span
                  >Pass hier falls notwendig die Zahl der Monate an, die du im
                  aktuellen Jahr in der Organisation tätig bist.
                </span>
              </v-tooltip>
              <input
                :style="colorOfInputElementsStyle"
                style="padding-left: 5px; width: 40px"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollMonthsInOrganization,
                    $event.target.value
                  )
                "
                :value="pollMonthsInOrganization"
              />
              <span class="flex-box-center-items-vertical"> / 12</span>
            </div>
            <span v-if="!isPollMonthsInOrganizationValid()" style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
        <v-col
          v-if="pollWorkingDays !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: center"
          >
            <div
              class="flex-box-center-items-horizontal"
              style="align-items: center"
            >
              <div style="margin: 5px">Arbeitstage (Woche)</div>
              <!-- <v-tooltip top>
            <template v-slot:activator="{on, attrs}"
              ><v-icon color="black" size="dense" dark v-bind="attrs" v-on="on"
                >mdi-help-circle</v-icon
              ></template
            >
            <span
              > Passen Sie hier falls notwendig die Zahl der Monate an, die Sie im aktuellen Jahr in der Organisation tätig sind. 
            </span>
          </v-tooltip> -->
              <input
                style="padding-left: 5px; width: 40px"
                :style="colorOfInputElementsStyle"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollWorkingDays,
                    $event.target.value
                  )
                "
                :value="pollWorkingDays"
              />
            </div>
            <span v-if="!isPollWorkingDaysValid()" style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
        <v-col
          v-if="pollHomeofficeDays !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: center"
          >
            <div class="flex-box-center-items-horizontal">
              <div style="margin: 5px">Homeoffice-Tage (Woche)</div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span
                  >Pass hier falls notwendig die Zahl deiner Homeoffice-Tage in
                  einer durchschnittlichen Arbeitswoche an.
                </span>
              </v-tooltip>
              <input
                style="padding-left: 5px; width: 40px"
                :style="colorOfInputElementsStyle"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollHomeofficeDays,
                    $event.target.value
                  )
                "
                :value="pollHomeofficeDays"
              />
            </div>
            <span v-if="!isPollHomeofficeDaysValid()" style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          pollPresenceDaysInLecturePeriod !== undefined ||
          pollMonthsLecturePeriod !== undefined
        "
      >
        <v-col
          v-if="pollMonthsLecturePeriod !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: flex-end"
          >
            <div class="flex-box-center-items-horizontal">
              <div style="margin: 5px">Davon Vorlesungszeit 2024</div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span
                  >Pass hier falls notwendig die Zahl der Monate an, die in die
                  Vorlesungszeit fielen.
                </span>
              </v-tooltip>
              <input
                :style="colorOfInputElementsStyle"
                style="padding-left: 5px; width: 40px"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollMonthsLecturePeriod,
                    $event.target.value
                  )
                "
                :value="pollMonthsLecturePeriod"
              />
              <span class="flex-box-center-items-vertical"> / 12</span>
            </div>
            <span v-if="!isPollMonthsLecturePeriodValid()" style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
        <v-col
          v-if="pollPresenceDaysInLecturePeriod !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: flex-end"
          >
            <div class="flex-box-center-items-horizontal">
              <div style="margin: 5px">
                Präsenztage/Woche (Vorlesungszeit) 2024
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span
                  >Durchschnittliche Präsenztage pro Woche in der
                  Vorlesungszeit.
                </span>
              </v-tooltip>
              <input
                :style="colorOfInputElementsStyle"
                style="padding-left: 5px; width: 40px"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollPresenceDaysInLecturePeriod,
                    $event.target.value
                  )
                "
                :value="pollPresenceDaysInLecturePeriod"
              />
              <span class="flex-box-center-items-vertical"> / {{pollPresenceDaysInLecturePeriodMax}}</span>
            </div>
            <span
              v-if="!isPollPresenceDaysInLecturePeriodValid()"
              style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          pollPresenceDaysInLecturePeriod !== undefined ||
          pollMonthsLectureFreePeriod !== undefined
        "
      >
        <v-col
          v-if="pollMonthsLectureFreePeriod !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: flex-end"
          >
            <div class="flex-box-center-items-horizontal">
              <div style="margin: 5px">Davon vorlesungsfreie Zeit 2024</div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span
                  >Pass hier falls notwendig die Zahl der Monate an, die in die
                  vorlesungsfreie Zeit fielen.
                </span>
              </v-tooltip>
              <input
                :style="colorOfInputElementsStyle"
                style="padding-left: 5px; width: 40px"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollMonthsLectureFreePeriod,
                    $event.target.value
                  )
                "
                :value="pollMonthsLectureFreePeriod"
              />
              <span class="flex-box-center-items-vertical"> / 12</span>
            </div>
            <span v-if="!isPollMonthsInOrganizationValid()" style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
        <v-col
          v-if="pollPresenceDaysInLecturePeriod !== undefined"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
        >
          <div
            class="flex-box-center-items-vertical"
            style="align-items: flex-end"
          >
            <div class="flex-box-center-items-horizontal">
              <div style="margin: 5px">
                Präsenztage/Woche (Vorlesungsfreie Zeit) 2024
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }"
                  ><v-icon
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span
                  >Durchschnittliche Präsenztage pro Woche in der
                  vorlesungsfreie Zeit.
                </span>
              </v-tooltip>
              <input
                :style="colorOfInputElementsStyle"
                style="padding-left: 5px; width: 40px"
                @change="
                  pollOverviewDataChanged(
                    PollData.PollPresenceDaysInLectureFreePeriod,
                    $event.target.value
                  )
                "
                :value="pollPresenceDaysInLectureFreePeriod"
              />
              <span class="flex-box-center-items-vertical"> / {{pollPresenceDaysInLectureFreePeriodMax}}</span>
            </div>
            <span
              v-if="!isPollPresenceDaysInLectureFreePeriodValid()"
              style="color: red"
              >Eintrag ungültig!</span
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
  <!-- </v-card-text> -->
</template>

<script>
import Vue from "vue";
import Transport from "../Transport.vue";
import { usePollStore } from "../../store/poll-store";
import { storeToRefs } from "pinia";
import * as utils from "../../utils";

export default Vue.extend({
  name: "TransportListing",

  components: {
    Transport,
  },

  props: {
    config: { type: Object, default: undefined },
    routes: { type: Array, default: () => [] },
  },

  data: () => ({
    PollData: {
      PollWorkingDays: 0,
      PollMonthsInOrganization: 1,
      PollHomeofficeDays: 2,
      PollMonthsInUniversity: 3,
      PollMonthsLecturePeriod: 4,
      PollMonthsLectureFreePeriod: 5,
      PollPresenceDaysInLecturePeriod: 6,
      PollPresenceDaysInLectureFreePeriod: 7,
    },
  }),

  mounted() {},

  methods: {
    isPollMonthsInOrganizationValid() {
      return usePollStore().isPollMonthsInOrganizationValid() || false;
    },
    isPollWorkingDaysValid() {
      return usePollStore().isPollWorkingDaysValid() || false;
    },
    isPollHomeofficeDaysValid() {
      return usePollStore().isPollHomeofficeDaysValid() || false;
    },
    isPollMonthsInUniversityValid() {
      return usePollStore().isPollMonthsInUniversityValid() || false;
    },
    isPollMonthsLecturePeriodValid() {
      return usePollStore().isPollMonthsLecturePeriodValid() || false;
    },
    isPollMonthsLectureFreePeriodValid() {
      return usePollStore().isPollMonthsLectureFreePeriodValid() || false;
    },
    isPollPresenceDaysInLecturePeriodValid() {
      return usePollStore().isPollPresenceDaysInLecturePeriodValid() || false;
    },
    isPollPresenceDaysInLectureFreePeriodValid() {
      return (
        usePollStore().isPollPresenceDaysInLectureFreePeriodValid() || false
      );
    },
    pollOverviewDataChanged(key, val) {
      if (val) {
        switch (key) {
          
          case this.PollData.PollWorkingDays:
            usePollStore().setPollWorkingDays(val);
            break;
          case this.PollData.PollMonthsInOrganization:
            usePollStore().setPollMonthsInOrganization(val);
            break;
          case this.PollData.PollHomeofficeDays:
            usePollStore().setPollHomeofficeDays(val);
            break;
          case this.PollData.PollMonthsInUniversity:
            usePollStore().pollMonthsInUniversity = val;
            // usePollStore().setPollMonthsInUniversity(val);
            break;
          case this.PollData.PollMonthsLecturePeriod:
            usePollStore().pollMonthsLecturePeriod = val;
            break;
          case this.PollData.PollMonthsLectureFreePeriod:
            usePollStore().pollMonthsLectureFreePeriod = val; 
            break;
          case this.PollData.PollPresenceDaysInLecturePeriod:
            usePollStore().pollPresenceDaysInLecturePeriod = val;
            break;
          case this.PollData.PollPresenceDaysInLectureFreePeriod:
            usePollStore().pollPresenceDaysInLectureFreePeriod = val;
            break;
        }
        // let generalSettings = this.pollOverviewData.generalSettings ? {...this.pollOverviewData.generalSettings} : {};
        // this.updatePollOverviewData({
        //   ...this.pollOverviewData,
        //   generalSettings:{
        //     ...generalSettings,
        //   [key]: Number(val)
        //   }

        // });
      }
    },
  },

  computed: {
    containerColor() {
      return this.$vuetify.theme.themes.light.secondary;
    },
    usePoll() {
      return usePollStore().usePoll.value || false;
    },
    title() {
      return storeToRefs(usePollStore()).title.value || "";
    },
    introductionText() {
      return storeToRefs(usePollStore()).introductionText.value || "";
    },
    introductionTitle() {
      return storeToRefs(usePollStore()).introductionTitle.value || "";
    },
    introductionSteps() {
      return storeToRefs(usePollStore()).introductionSteps.value || [];
    },
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },
    pollHomeofficeDays() {
      return storeToRefs(usePollStore()).pollHomeofficeDays.value;
    },
    pollWorkingDays() {
      return storeToRefs(usePollStore()).pollWorkingDays.value;
    },
    pollMonthsInOrganization() {
      return storeToRefs(usePollStore()).pollMonthsInOrganization.value;
    },
    pollMonthsInOrganizationMax() {
      return storeToRefs(usePollStore()).pollMonthsInOrganizationMax.value;
    },
    pollMonthsInUniversity() {
      return storeToRefs(usePollStore()).pollMonthsInUniversity.value;
    },
     pollMonthsInUniversityMax() {
      return storeToRefs(usePollStore()).pollMonthsInUniversityMax.value;
    },
    pollMonthsLecturePeriod() {
      return storeToRefs(usePollStore()).pollMonthsLecturePeriod.value;
    },
    pollMonthsLectureFreePeriod() {
      return storeToRefs(usePollStore()).pollMonthsLectureFreePeriod.value;
    },
    pollPresenceDaysInLecturePeriod() {
      return storeToRefs(usePollStore()).pollPresenceDaysInLecturePeriod.value;
    },
    pollPresenceDaysInLecturePeriodMax() {
      return storeToRefs(usePollStore()).pollPresenceDaysInLecturePeriodMax.value;
    },
    pollPresenceDaysInLectureFreePeriod() {
      return storeToRefs(usePollStore()).pollPresenceDaysInLectureFreePeriod
        .value;
    },
     pollPresenceDaysInLectureFreePeriodMax() {
      return storeToRefs(usePollStore()).pollPresenceDaysInLectureFreePeriodMax
        .value;
    },
  },

  watch: {},
});
</script>

<style scoped>
.full-width {
  width: 100%;
}
.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
