/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.66
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import OneWayRoute from './OneWayRoute';
import RouteMetrics from './RouteMetrics';

/**
 * The RouteTotal model module.
 * @module model/RouteTotal
 * @version 0.0.2
 */
export default class RouteTotal {
  /**
   * Constructs a new <code>RouteTotal</code>.
   * contains whole 2-way route average metric values of a single transport type like car bicycle
   * @alias module:model/RouteTotal
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RouteTotal</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RouteTotal} obj Optional instance to populate.
   * @return {module:model/RouteTotal} The populated <code>RouteTotal</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RouteTotal();
      if (data.hasOwnProperty('toDestination'))
        obj.toDestination = OneWayRoute.constructFromObject(data['toDestination']);
      if (data.hasOwnProperty('fromDestination'))
        obj.fromDestination = OneWayRoute.constructFromObject(data['fromDestination']);
      if (data.hasOwnProperty('metrics'))
        obj.metrics = RouteMetrics.constructFromObject(data['metrics']);
      if (data.hasOwnProperty('routeWarnings'))
        obj.routeWarnings = ApiClient.convertToType(data['routeWarnings'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {module:model/OneWayRoute} toDestination
 */
RouteTotal.prototype.toDestination = undefined;

/**
 * @member {module:model/OneWayRoute} fromDestination
 */
RouteTotal.prototype.fromDestination = undefined;

/**
 * @member {module:model/RouteMetrics} metrics
 */
RouteTotal.prototype.metrics = undefined;

/**
 * @member {Array.<String>} routeWarnings
 */
RouteTotal.prototype.routeWarnings = undefined;

