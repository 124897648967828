/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.66
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import RouteLeg from './RouteLeg';

/**
 * The OneWayRoute model module.
 * @module model/OneWayRoute
 * @version 0.0.2
 */
export default class OneWayRoute {
  /**
   * Constructs a new <code>OneWayRoute</code>.
   * @alias module:model/OneWayRoute
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OneWayRoute</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OneWayRoute} obj Optional instance to populate.
   * @return {module:model/OneWayRoute} The populated <code>OneWayRoute</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OneWayRoute();
      if (data.hasOwnProperty('transport'))
        obj.transport = ApiClient.convertToType(data['transport'], 'String');
      if (data.hasOwnProperty('legs'))
        obj.legs = ApiClient.convertToType(data['legs'], [RouteLeg]);
    }
    return obj;
  }
}

/**
 * @member {String} transport
 */
OneWayRoute.prototype.transport = undefined;

/**
 * @member {Array.<module:model/RouteLeg>} legs
 */
OneWayRoute.prototype.legs = undefined;

