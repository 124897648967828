/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.66
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The OepvTicketMode model module.
 * @module model/OepvTicketMode
 * @version 0.0.2
 */
export default class OepvTicketMode {
  /**
   * Constructs a new <code>OepvTicketMode</code>.
   * @alias module:model/OepvTicketMode
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OepvTicketMode</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OepvTicketMode} obj Optional instance to populate.
   * @return {module:model/OepvTicketMode} The populated <code>OepvTicketMode</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OepvTicketMode();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('compoundShortName'))
        obj.compoundShortName = ApiClient.convertToType(data['compoundShortName'], 'String');
      if (data.hasOwnProperty('compoundFullName'))
        obj.compoundFullName = ApiClient.convertToType(data['compoundFullName'], 'String');
      if (data.hasOwnProperty('transportClass'))
        obj.transportClass = ApiClient.convertToType(data['transportClass'], 'Number');
    }
    return obj;
  }
}

/**
 * unique id of the ticket mode
 * @member {String} id
 */
OepvTicketMode.prototype.id = undefined;

/**
 * title of the ticket
 * @member {String} title
 */
OepvTicketMode.prototype.title = undefined;

/**
 * short-name of the compound
 * @member {String} compoundShortName
 */
OepvTicketMode.prototype.compoundShortName = undefined;

/**
 * Long name of the compound
 * @member {String} compoundFullName
 */
OepvTicketMode.prototype.compoundFullName = undefined;

/**
 * train class
 * @member {Number} transportClass
 */
OepvTicketMode.prototype.transportClass = undefined;

