import { toQueryString } from '@norwin/javascript-is-bad'
import { zonedTimeToUtc } from 'date-fns-tz'
import { CR_API_KEY } from './models/constants-general';

const baseUrl = () => `${window.CLEVERROUTE_HOST || '.'}/api/v1`

export async function fetchConfig() {
  const res = await fetch(`${baseUrl()}/config`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': CR_API_KEY

    }
  });
  const body = await res.json()
  if (body.status !== 'success')
    throw new Error(`could not load config: ${body.message}`)
  const cfg = body.data
  cfg.thirdparty = JSON.parse(atob(cfg.thirdparty))
  return cfg
}

export async function fetchFrontendConfig() {
  const res = await fetch(`${baseUrl()}/frontendConfig`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': CR_API_KEY

    }
  });
  const body = await res.json()
  if (body.status !== 'success')
    throw new Error(`could not load config: ${body.message}`)
  const cfg = body.data
  cfg.thirdparty = JSON.parse(atob(cfg.thirdparty))
  return cfg
}


export async function savePollToDB(poll) {


  // // only if departure is set then pass it to backend
  // if (params.departure && params.departure !== "") {
  //   condParams = { ...condParams, departure: zonedTimeToUtc(params.departure, 'Europe/Berlin') };
  // } else {
  //   condParams = { ...condParams };
  // }
  const url = `${baseUrl()}/poll`
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': CR_API_KEY

    },
    body: JSON.stringify(poll)
  });

  const body = await res.json()
  if (body.status === 'success')
    return body.data

  const err = body.message || JSON.stringify(body)
  throw new Error(`Fehler beim Abspeichern der Umfrage: ${err}`)
}

export async function fetchUserPollId(clientName, pollDate, pollType) {
  let condParams = {
    clientName, pollDate, pollType
  };

  const url = `${baseUrl()}/poll/userId?${toQueryString(condParams)}`
  // return fetch(url);
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': CR_API_KEY
    }
  });

  const body = await res.json()
  if (body.status === 'success') {
    return body.data.userPollId
  }

  const err = body.message || JSON.stringify(body)
  throw new Error(`Fehler beim Geocoding: ${err}`)
}

export async function fetchTrainstationsWithRadius(params) {
  let condParams = {
    ...params
  };

  // // only if departure is set then pass it to backend
  // if (params.departure && params.departure !== "") {
  //   condParams = { ...condParams, departure: zonedTimeToUtc(params.departure, 'Europe/Berlin') };
  // } else {
  //   condParams = { ...condParams };
  // }
  const url = `${baseUrl()}/trainstationsWithinRadius?${toQueryString(condParams)}`
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': CR_API_KEY

    }
  });

  const body = await res.json()
  if (body.status === 'success')
    return body.data

  const err = body.message || JSON.stringify(body)
  throw new Error(`Fehler beim Suchen der Routen: ${err}`)
}


export async function fetchSingleRoute(params, endpoint="singleRoute") {
  let condParams = {
    ...params,
    arrival: zonedTimeToUtc(params.arrival, 'Europe/Berlin')
  };

  // only if departure is set then pass it to backend
  if (params.departure && params.departure !== "") {
    condParams = { ...condParams, departure: zonedTimeToUtc(params.departure, 'Europe/Berlin') };
  } else {
    condParams = { ...condParams };
  }

  const url = `${baseUrl()}/${endpoint}?${toQueryString(condParams)}`
  // return fetch(url);
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': CR_API_KEY
    }
  });

  const body = await res.json()
  if (body.status === 'success') {
    let routes = body.data; 
    return {routes: routes, id: condParams.transportGroupId}
  }

  const err = body.message || JSON.stringify(body)
  throw new Error(`Fehler beim Suchen der Routen: ${err}`)
}


/**
 * fetches the proposals of addresses for a typed in term
 * 
 * @param {*} term a string which may be comma-seperated to indicate the parts of the address like "bebelstr, stuttgart"
 * @returns array of proposals as objects with the name of the address
 */
 export async function fetchSharingstationsWithinRadius(condParams) {
    const url = `${baseUrl()}/sharingstationsWithinRadius?${toQueryString(condParams)}`
  // return fetch(url);
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': CR_API_KEY
    }
  });

  const body = await res.json();
  if (body.status === 'success') {
    return body.data.data
  }

  const err = body.message || JSON.stringify(body)
  throw new Error(`Fehler beim Geocoding: ${err}`)
}

/**
 * fetches the proposals of addresses for a typed in term
 * 
 * @param {*} term a string which may be comma-seperated to indicate the parts of the address like "bebelstr, stuttgart"
 * @returns array of proposals as objects with the name of the address
 */
export async function fetchGeocoding(term) {
  let condParams = {
    term: term
  };

  const url = `${baseUrl()}/geocoding?${toQueryString(condParams)}`
  // return fetch(url);
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': CR_API_KEY
    }
  });

  const body = await res.json()
  if (body.status === 'success') {
    return body.data.data
  }

  const err = body.message || JSON.stringify(body)
  throw new Error(`Fehler beim Geocoding: ${err}`)
}

export function fetchRouteParamsValid(params, destinations) {
  const { arrival, origin, destination } = params;

  const isSingleDest = destinations.length < 2

  return arrival && (origin) && (isSingleDest || destination)
}
