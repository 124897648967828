/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.66
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The ResponseStatus model module.
 * @module model/ResponseStatus
 * @version 0.0.2
 */
export default class ResponseStatus {
  /**
   * Constructs a new <code>ResponseStatus</code>.
   * @alias module:model/ResponseStatus
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ResponseStatus</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ResponseStatus} obj Optional instance to populate.
   * @return {module:model/ResponseStatus} The populated <code>ResponseStatus</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ResponseStatus();
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'Boolean');
      if (data.hasOwnProperty('message'))
        obj.message = ApiClient.convertToType(data['message'], 'String');
    }
    return obj;
  }
}

/**
 * whether operation was successful
 * @member {Boolean} status
 */
ResponseStatus.prototype.status = undefined;

/**
 * error or warn message
 * @member {String} message
 */
ResponseStatus.prototype.message = undefined;

