var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"color":"secondary"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_vm._l((_vm.buttonRow),function(ref){
var url = ref.url;
var text = ref.text;
return _c('v-btn',{key:url,attrs:{"text":"","rounded":"","href":url,"target":"_blank"},domProps:{"textContent":_vm._s(text)}})}),_c('PrivacyDialog',{attrs:{"impressumUrl":_vm.impressumUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v("Datenschutz")])]}}])})],2),(_vm.showFooterLogos && _vm.imgRow && _vm.imgRow.length > 0)?_c('v-row',{staticClass:"mt-6",attrs:{"justify":"center","align":"center"}},_vm._l((_vm.imgRow),function(ref){
var url = ref.url;
var text = ref.text;
var image = ref.image;
var classes = ref.classes;
return _c('a',{key:url,staticClass:"ma-6",attrs:{"href":url,"target":"_blank"}},[(text)?_c('div',{domProps:{"textContent":_vm._s(text)}}):_vm._e(),_c('img',{class:classes,staticStyle:{"max-height":"180px"},attrs:{"src":image}})])}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }