var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    'background-color': 'white',

    border: '2px solid ' + _vm.$vuetify.theme.themes.light.secondary,
  }),attrs:{"elevation":"2"}},[(_vm.introductionTitle || _vm.introductionText)?_c('div',{staticStyle:{"display":"flex","flex-orientation":"row"}},[_c('div',{staticStyle:{"padding":"15px","width":"70%"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.introductionTitle)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.introductionText)}})]),_c('div',{staticStyle:{"width":"30%","margin-top":"10px","display":"flex","flex-direction":"row","justify-content":"space-evenly"}},[(true)?_c('div',{staticStyle:{"width":"50%","padding":"15px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"logos/customer_logo.png"}})]):_vm._e(),(true)?_c('div',{staticStyle:{"width":"50%","padding":"15px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"cleverroute-logo-green.png"}})]):_vm._e()])]):_vm._e(),_c('div',{staticStyle:{"padding":"15px"},style:(("background-color: " + _vm.containerColor))},[(
        _vm.pollMonthsInUniversityMax !== undefined ||
        _vm.pollMonthsInOrganizationMax !== undefined ||
        _vm.pollWorkingDays !== undefined ||
        _vm.pollHomeofficeDays !== undefined
      )?_c('v-divider',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"orientation":"horizontal"}}):_vm._e(),_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.introductionSteps),function(myText,i){return _c('div',{key:i,domProps:{"innerHTML":_vm._s(myText)}})}),0)],1),(_vm.pollMonthsInUniversity !== undefined)?_c('v-row',[(_vm.pollMonthsInUniversity !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"flex-end"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Monate an der Hochschule 2024")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,502891596)},[_c('span',[_vm._v("Pass hier falls notwendig die Zahl der Monate an, die du im aktuellen Jahr an der Hochschule gewesen warst. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollMonthsInUniversity},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollMonthsInUniversity,
                  $event.target.value
                )}}}),_c('span',{staticClass:"flex-box-center-items-vertical"},[_vm._v(" / 12")])],1),(!_vm.isPollMonthsInOrganizationValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e()],1):_vm._e(),(
        _vm.pollMonthsInOrganization !== undefined ||
        _vm.pollWorkingDays !== undefined ||
        _vm.pollHomeofficeDays !== undefined
      )?_c('v-row',[(_vm.pollMonthsInOrganization !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Monate in Organisation 2024")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,502891596)},[_c('span',[_vm._v("Pass hier falls notwendig die Zahl der Monate an, die du im aktuellen Jahr in der Organisation tätig bist. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollMonthsInOrganization},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollMonthsInOrganization,
                  $event.target.value
                )}}}),_c('span',{staticClass:"flex-box-center-items-vertical"},[_vm._v(" / 12")])],1),(!_vm.isPollMonthsInOrganizationValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e(),(_vm.pollWorkingDays !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"flex-box-center-items-horizontal",staticStyle:{"align-items":"center"}},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Arbeitstage (Woche)")]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollWorkingDays},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollWorkingDays,
                  $event.target.value
                )}}})]),(!_vm.isPollWorkingDaysValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e(),(_vm.pollHomeofficeDays !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Homeoffice-Tage (Woche)")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,502891596)},[_c('span',[_vm._v("Pass hier falls notwendig die Zahl deiner Homeoffice-Tage in einer durchschnittlichen Arbeitswoche an. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollHomeofficeDays},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollHomeofficeDays,
                  $event.target.value
                )}}})],1),(!_vm.isPollHomeofficeDaysValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e()],1):_vm._e(),(
        _vm.pollPresenceDaysInLecturePeriod !== undefined ||
        _vm.pollMonthsLecturePeriod !== undefined
      )?_c('v-row',[(_vm.pollMonthsLecturePeriod !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"flex-end"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Davon Vorlesungszeit 2024")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,502891596)},[_c('span',[_vm._v("Pass hier falls notwendig die Zahl der Monate an, die in die Vorlesungszeit fielen. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollMonthsLecturePeriod},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollMonthsLecturePeriod,
                  $event.target.value
                )}}}),_c('span',{staticClass:"flex-box-center-items-vertical"},[_vm._v(" / 12")])],1),(!_vm.isPollMonthsLecturePeriodValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e(),(_vm.pollPresenceDaysInLecturePeriod !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"flex-end"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v(" Präsenztage/Woche (Vorlesungszeit) 2024 ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,502891596)},[_c('span',[_vm._v("Durchschnittliche Präsenztage pro Woche in der Vorlesungszeit. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollPresenceDaysInLecturePeriod},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollPresenceDaysInLecturePeriod,
                  $event.target.value
                )}}}),_c('span',{staticClass:"flex-box-center-items-vertical"},[_vm._v(" / "+_vm._s(_vm.pollPresenceDaysInLecturePeriodMax))])],1),(!_vm.isPollPresenceDaysInLecturePeriodValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e()],1):_vm._e(),(
        _vm.pollPresenceDaysInLecturePeriod !== undefined ||
        _vm.pollMonthsLectureFreePeriod !== undefined
      )?_c('v-row',[(_vm.pollMonthsLectureFreePeriod !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"flex-end"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Davon vorlesungsfreie Zeit 2024")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,502891596)},[_c('span',[_vm._v("Pass hier falls notwendig die Zahl der Monate an, die in die vorlesungsfreie Zeit fielen. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollMonthsLectureFreePeriod},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollMonthsLectureFreePeriod,
                  $event.target.value
                )}}}),_c('span',{staticClass:"flex-box-center-items-vertical"},[_vm._v(" / 12")])],1),(!_vm.isPollMonthsInOrganizationValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e(),(_vm.pollPresenceDaysInLecturePeriod !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"flex-end"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v(" Präsenztage/Woche (Vorlesungsfreie Zeit) 2024 ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,502891596)},[_c('span',[_vm._v("Durchschnittliche Präsenztage pro Woche in der vorlesungsfreie Zeit. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollPresenceDaysInLectureFreePeriod},on:{"change":function($event){return _vm.pollOverviewDataChanged(
                  _vm.PollData.PollPresenceDaysInLectureFreePeriod,
                  $event.target.value
                )}}}),_c('span',{staticClass:"flex-box-center-items-vertical"},[_vm._v(" / "+_vm._s(_vm.pollPresenceDaysInLectureFreePeriodMax))])],1),(!_vm.isPollPresenceDaysInLectureFreePeriodValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }