<template>
  <div class="flex-box-center-items-vertical">
    <!-- <div class="flex-box-left-aligned-horizontal">
      Wird geutzt?
    <v-tooltip top>
        <template v-slot:activator="{on, attrs}"
          ><v-icon
            :color="$vuetify.theme.themes.light.secondary"
            size="dense"
            dark
            v-bind="attrs"
            v-on="on"
            >mdi-help-circle</v-icon
          ></template
        >
        <span>Wird diese Fahrtvariante genutzt?</span>
      </v-tooltip>
    </div> -->
    <!-- <template v-slot:label> -->
    <div class="flex-box-left-aligned-horizontal">
      <span>Wird genutzt?</span>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }"
          ><v-icon
            :color="$vuetify.theme.themes.light.secondary"
            size="dense"
            dark
            v-bind="attrs"
            v-on="on"
            >mdi-help-circle</v-icon
          ></template
        >
        <span
          >Wird diese Fahrtvariante genutzt? Bitte bewerte auch diese
          Fahrtvariante durch Auswahl der Sterne.</span
        >
      </v-tooltip>
    </div>
    <!-- </template> -->
    <v-radio-group
      hide-details="auto"
      :column="false"
      class="flex-box-left-aligned-horizontal"
      style="margin-top: 0px !important"
      @change="isTransportUsedChanged($event, transportId)"
      :value="getIsTransportUsed(transportId)"
    >
      <v-radio :value="true">
        <template v-slot:label>
          <span>ja</span>
        </template></v-radio
      >
      <v-radio :value="false">
        <template v-slot:label>
          <span>nein</span>
        </template></v-radio
      >
    </v-radio-group>
    <span v-if="transportMode == 'car'"
      >Personenanzahl im PKW</span
    >
    <div
      v-if="transportMode == 'car'"
      style="height: 100%; width: 100%"
      class="flex-box-left-aligned-horizontal"
    >
      <v-btn
        @click="decrementPassengers(transportId)"
        style="
          min-width: 20%;
          padding: 0 3px;
          height: 100%;
          align-items: center;
        "
      >
        <v-icon> remove </v-icon>
      </v-btn>
      <input
        type="text"
        outlined
        @change="nPassengersChanged(transportId, $event)"
        :value="getNumberPassengers(transportId)"
        style="height: 100%; min-width: 60%; text-align: center"
        :style="colorOfInputElementsStyle"
      />
      <v-btn
        @click="incrementPassengers(transportId)"
        style="min-width: 20%; padding: 0 3px; height: 100%; text-align: center"
      >
        <v-icon> add </v-icon>
      </v-btn>
      <!-- </div> -->
    </div>
    <PollRating
      :transportId="transportId"
      :config="config"
      :routes="routes"
    ></PollRating>
    <!-- <v-rating
  :color="$vuetify.theme.themes.light.secondary"
  empty-icon="$mdiStarOutline"
  full-icon="$mdiStar"
  half-icon="$mdiStarHalfFull"
  hover
  size="small"
></v-rating> -->
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import Vue from "vue";
import { usePollStore } from "../../store/poll-store";
import PollRating from "./PollRating.vue";
import * as utils from "../../utils";

export default Vue.extend({
  name: "PollCompanyInputForm",

  components: {
    PollRating,
  },

  props: {
    config: { type: Object, default: undefined },
    transportId: { type: String, default: "" },
    transportMode: { type: String, default: "" },
    routes: { type: Array, default: () => [] },
  },

  data: () => ({}),

  mounted() {
  },

  methods: {
    isSmallScreenWidth() {
      return window.innerWidth < 700;
    },
    isTransportUsedChanged(val, transportId) {
      usePollStore().setTransportUsed(val, transportId);
      // this.transportUsedArr[i] = val;
    },
    getIsTransportUsed(transportId) {
      return usePollStore().isTransportUsed(transportId);
      // return this.transportUsedArr[i];
    },
    incrementPassengers(transportId) {
      usePollStore().incrementNumberPassengers(transportId);

      // this.numberPassengers[transportId].freq += 1;
    },
    decrementPassengers(transportId) {
      // if (this.numberPassengers[transportId].freq > 0) {
      //   this.numberPassengers[transportId].freq -= 1;
      // }
      usePollStore().decrementNumberPassengers(transportId);
    },
    nPassengersChanged(transportId, event) {
      let val = event.target?.value;
      const num = Number(val);
      usePollStore().setNumberPassengers(num, transportId);
      // this.numberPassengers[transportId].freq = num;
    },
    getNumberPassengers(transportId) {
      return usePollStore().numberPassengersOfTransport(transportId);
      // if (this.numberPassengers.length - 1 >= i) {
      //   return this.numberPassengers[i].freq;
      // } else {
      //   return 0;
      // }
    },
  },

  computed: {
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },
  },

  watch: {
    routes: {
      handler: function (o, n) {
        this.lastUpdated = Date.now();
        // init poll frequencies
        this.routes.forEach((r, i) => {
          if (
            !storeToRefs(usePollStore()).frequencies.value.find(
              (poll) => poll.transportId === r.transport.id
            )
          ) {
              usePollStore().addNumberPassengers({
              freq: 0,
              transportId: r.transport.id,
            });
             usePollStore().addTransportUsed({
              used: false,
              transportId: r.transport.id,
            });
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>


<style scoped>
.full-width {
  width: 100%;
}

.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
