<template>
  <div class="flex-box-left-aligned-horizontal">
    <v-tooltip top>
      <template v-slot:activator="{on, attrs}"
        ><div v-bind="attrs" v-on="on">
          <v-rating
            v-bind="attrs"
            v-on="on"
            @input="nStarsChanged(transportId, $event)"
        :value="getNumberStars(transportId)"

        background-color="#E9D90F"
            color="#E9D90F"
            :size="isSmallScreenWidth() ? '15' : '25'"
            >my rating</v-rating
          >
        </div>
      </template>
      <span>Wie findest du diese Fahrtvariante? </span>
    </v-tooltip>
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import Vue from "vue";
import {usePollStore} from "../../store/poll-store";
import * as utils from "../../utils";

export default Vue.extend({
  name: "PollRating",

  components: {},

  props: {
    transportId: {type: String, default: ""},
    config: {type: Object, default: undefined},
    transportMode: {type: String, default: ""},
    routes: {type: Array, default: () => []},
  },

  data: () => ({}),

  mounted() {},

  methods: {
    isSmallScreenWidth() {
      return window.innerWidth < 700;
    },
    getNumberStars(transportId) {
      return usePollStore().getNumberStars(transportId);
    },
    nStarsChanged(transportId, val) {
      const num = Number(val);
      usePollStore().setNumberStars(num, transportId);
    },
  },

  computed: {
    colorOfInputElementsValue() {
      return utils.colorOfInputElementsValue.bind(this)();
    },
  },

  watch: {
    routes: {
      handler: function (o, n) {
        this.lastUpdated = Date.now();
        // init poll frequencies
        this.routes.forEach((r, i) => {
          if (
            !storeToRefs(usePollStore()).numberStars.value.find(
              (poll) => poll.transportId === r.transport.id
            )
          ) {
            usePollStore().addNumberStars({
              freq: 0,
              transportId: r.transport.id,
            });
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>

<style scoped>
.full-width {
  width: 100%;
}
.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
