/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.66
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The RoutingSettings model module.
 * @module model/RoutingSettings
 * @version 0.0.2
 */
export default class RoutingSettings {
  /**
   * Constructs a new <code>RoutingSettings</code>.
   * routing-settings of an own vehicle or public transport
   * @alias module:model/RoutingSettings
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RoutingSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RoutingSettings} obj Optional instance to populate.
   * @return {module:model/RoutingSettings} The populated <code>RoutingSettings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RoutingSettings();
      if (data.hasOwnProperty('vehicleId'))
        obj.vehicleId = ApiClient.convertToType(data['vehicleId'], 'String');
      if (data.hasOwnProperty('maxTrainChanges'))
        obj.maxTrainChanges = ApiClient.convertToType(data['maxTrainChanges'], 'Number');
      if (data.hasOwnProperty('trainChangeTimeMin'))
        obj.trainChangeTimeMin = ApiClient.convertToType(data['trainChangeTimeMin'], 'Number');
      if (data.hasOwnProperty('minDistanceKm'))
        obj.minDistanceKm = ApiClient.convertToType(data['minDistanceKm'], 'Number');
      if (data.hasOwnProperty('maxDistanceKm'))
        obj.maxDistanceKm = ApiClient.convertToType(data['maxDistanceKm'], 'Number');
      if (data.hasOwnProperty('selected'))
        obj.selected = ApiClient.convertToType(data['selected'], 'Boolean');
    }
    return obj;
  }
}

/**
 * unique Id of the vehicle in string-form like car, bicycle, escooter, scooter
 * @member {String} vehicleId
 */
RoutingSettings.prototype.vehicleId = undefined;

/**
 * used only for public transport vehicles. Max number of changes of trains allowed
 * @member {Number} maxTrainChanges
 */
RoutingSettings.prototype.maxTrainChanges = undefined;

/**
 * used only for public transport vehicles.
 * @member {Number} trainChangeTimeMin
 */
RoutingSettings.prototype.trainChangeTimeMin = undefined;

/**
 * used only for individual vehicles (no OEPV). min-distance in km the vehicle should be used, i.e. if the distance this vehicle would be used for is less, then the route is discarded
 * @member {Number} minDistanceKm
 */
RoutingSettings.prototype.minDistanceKm = undefined;

/**
 * used only for individual vehicles (no OEPV). max-distance in km the vehicle should be used, i.e. if the distance this vehicle would be used for is more, then the route is discarded
 * @member {Number} maxDistanceKm
 */
RoutingSettings.prototype.maxDistanceKm = undefined;

/**
 * whether this vehicle was selected for use by the user, i.e. if the user possesdes this vehicles
 * @member {Boolean} selected
 */
RoutingSettings.prototype.selected = undefined;

