<template>
  <v-card outlined class="hovercard" :class="expanded && 'expanded'">
    <v-progress-linear
      rounded
      v-if="loading"
      height="6"
      striped
      :value="percentageTransportsComputed"
    />
    <v-card-title class="py-3 py-md-0 pr-md-0" @click="toggleExpand">
      <v-row no-gutters class="align-center full-max-width">
        <v-col
          :cols="printView ? undefined : 12"
          md="6"
          lg="4"
          xl="5"
          class="d-flex align-center my-0 my-md-3"
        >
          <TransportSymbolsBox
            :transport="t.transport"
            boxAlignment="horizontal"
            :color="$vuetify.theme.isDark ? '' : 'primary'"
            :isOverviewMode="true"
          ></TransportSymbolsBox>
          <v-tooltip top v-if="warningMessage">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" x-small style="color: red">
                *
              </span>
            </template>
            <span>{{ warningMessage }}</span>
          </v-tooltip>

          <img
            v-if="getProviderIconPath(t)"
            :src="getProviderIconPath(t)"
            style="margin: 5px; width: 60px"
            class="top-widget-logo"
          />
          <p
            v-else-if="getProviderName(t)"
            style="margin: 5px; width: 60px"
            class="top-widget-logo"
          >
            {{ getProviderName(t) }}
          </p>
        </v-col>

        <v-col class="mt-2 mb-0 my-md-3">
          <v-row no-gutters>
            <v-col
              v-for="m in metrics"
              :key="m.name"
              cols="6"
              lg="3"
              class="pa-1 text-md-center d-flex align-center justify-center font-weight-light text-break"
            >
              <v-icon large>{{ formatMetricIcon(m, t.transport) }}</v-icon>
              <span class="pl-1">
                {{ formatMetric(m.id, t) }}
              </span>
              <v-tooltip top v-if="priceHint && m.id == 'cost'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" x-small style="color: red">
                    *
                  </span>
                </template>
                <span>{{ priceHint }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>

    <v-expand-transition>
      <div v-if="expanded" class="details">
        <v-divider />
        <v-container class="my-3">
          <v-row justify="end">
            <v-col v-if="!!description" class="pt-0">
              <v-subheader class="mt-0 text-uppercase"
                >Beschreibung</v-subheader
              >
              <div
                class="pl-4 text-body-1 text-justify"
                v-once
                v-html="description"
              />
            </v-col>

            <v-col cols="12" sm="auto" class="d-print-none d-flex justify-end">
              <div class="d-flex flex-column flex-xl-row full-width">
                <v-btn
                  v-for="{ name, url } in bookingLinks"
                  :key="name + url"
                  :href="url"
                  target="_blank"
                  :block="$vuetify.breakpoint.xs"
                  large
                  color="primary"
                  class="mr-lg-6 mb-3"
                >
                  <v-icon class="mr-1">payments</v-icon>
                  {{ name }}
                </v-btn>

                <TransportConfig :transport="t.transport">
                  <template v-slot:default="{ attrs, on }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      :large="$vuetify.breakpoint.smAndUp"
                      :block="$vuetify.breakpoint.xs"
                      class="mr-lg-6 mb-3"
                    >
                      <v-icon class="mr-1">info</v-icon>
                      Parameter
                    </v-btn>
                  </template>
                </TransportConfig>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="7">
              <Route
                :searchParams="searchParams"
                :routes="routes"
                :printView="printView"
                @geometry-changed="updateMap"
                :directionTab="direction"
                :updateDirection="updateDirection"
              />
            </v-col>
            <v-col cols="12" md="5" v-if="!printView">
              <Map
                height="400px"
                :geojsonRoutes="geojsonRoutes"
                :gesture-handling="$vuetify.breakpoint.mdAndDown"
                :originLonLatStr="originLonLat"
                :destinationLonLatStr="destinationLonLat"
                :disableAutoFocus="true"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-expand-transition>

    <v-divider class="d-print-none" />
    <v-card-actions
      class="justify-center d-print-none"
      role="button"
      @click="toggleExpand"
    >
      <v-icon v-text="expanded ? 'expand_less' : 'expand_more'" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { toQueryString } from "@norwin/javascript-is-bad";
import Vue from "vue";
import { metricsDisplayParams } from "../ui-mappings";
import {
  formatDuration,
  formatNumberToLocaleAnd2Fixed,
  formatCo2ByValue,
} from "../utils";
import Map from "./Map.vue";
import Route from "./Route.vue";
import TransportConfig from "./TransportConfig.vue";
import TransportSymbolsBox from "./TransportSymbolsBox.vue";

export default Vue.extend({
  name: "Transport",

  components: {
    Route,
    Map,
    TransportConfig,
    TransportSymbolsBox,
  },

  props: {
    searchParams: {
      type: Object,
      default: () => ({
        origin: "",
        departure: "", // contains Date instances when filled out
        arrival: "",
        destination: "",
        includeIntermodal: false,
        minutesCongestion: "0",
        minutesParking: "0",
        originLonLat: "",
        destinationLonLat: "",
      }),
    },
    onlyJourneyThere: {
      default: false,
      type: Boolean,
    },
    rideshareHost: String,
    reservationToolHost: String,
    printView: Boolean,
    t: {
      type: Object,
      default: () => ({
        transport: {
          id: "ebike",
          ui: {
            icon: "electric_bike",
            name: "Pedelec",
            description: "Lorem ipsum dolor sit amet",
          },
          metricParams: {},
        },
        toDestination: { legs: [], metrics: {} },
        fromDestination: { legs: [], metrics: {} },
        routeWarnings: [],
        metrics: {
          co2: 55.336246,
          time: 103.639,
          cost: 2999.3333,
          sport: 366.222,
        },
      }),
    },
    loading: { type: Boolean, default: false },
    percentageTransportsComputed: { type: Number, default: 0 },
  },

  data: () => ({
    expanded: false,
    metrics: metricsDisplayParams,
    geojsonRoutes: [],
    direction: 0,
    priceHint: "",
  }),
  created() {
    // replace icon for high-speed-train if there is not high-speed leg for this transport variant
    if (
      this.t.transport.ui.svgNameIcon &&
      this.t.transport.ui.svgNameIcon === "high_speed_train"
    ) {
      // define the train types abbreviation for high-speed train
      const fernverkehrTrainTypes = ["ice", "ic", "ec"];
      // for each leg check whether uses is high-speed train
      const fastTrainLegs = this.t.toDestination.legs.filter((l) => {
        // extract just the train-type abbreviation
        const re = l.description.match(/^\D*(?=\d*$)/g);

        if (re === null) {
          return false;
        }
        let str = re[0].trim().toLowerCase();
        // check whether it's high-speed-train
        if (fernverkehrTrainTypes.includes(str)) {
          return true;
        }
      });

      // if no leg is high-speed then replace high-speed icon with normal train
      if (fastTrainLegs.length === 0) {
        delete this.t.transport.ui.svgNameIcon;
        this.t.transport.ui.materialIcon = "directions_transit";
      }
    }
  },
  computed: {
    originLonLat() {
      return this.direction === 0
        ? this.searchParams.originLonLat
        : this.searchParams.destinationLonLat;
    },
    destinationLonLat() {
      return this.direction === 0
        ? this.searchParams.destinationLonLat
        : this.searchParams.originLonLat;
    },

    routes() {
      return [this.t.toDestination, this.t.fromDestination];
    },

    bookingLinks() {
      const links = [];
      const { firstMile, lastMile, type, routing, isElectric } =
        this.t.transport;

      if (type === "public_transit") {
        let bahnLinks = this.generateBahnBookingLinks();
        // if no bahnLinks could be parsed (because it is RMV and not DB) then return empty array
        if (!bahnLinks) {
          return [];
        }
        bahnLinks.forEach((link) => links.push(link));
        // links.push({ name: "Bahn buchen", url: "https://bahn.de" });
      } else if (type === "individual" && routing.mode === "car") {
        const url = this.clever2getherURL;
        if (url) links.push({ name: "Fahrgemeinschaft anbieten", url });

        const url2 = this.reservationToolURL;
        // TODO: also check if destination has feature enabled?
        if (isElectric && url2)
          links.push({ name: "Ladestation reservieren", url: url2 });
      } else if (type === "pendlerportal" && routing.mode === "car") {
        // const url = this.clever2getherURL;
        // if (url) links.push({ name: "Fahrgemeinschaft finden", url });

        const url2 = this.reservationPendlerportalURL;
        // TODO: also check if destination has feature enabled?
        if (isElectric && url2)
          links.push({ name: "Ladestation reservieren", url: url2 });
      }

      const addLink = (transport) => {
        if (!transport?.ui.bookingLink) return;
        const { bookingLink, name } = transport.ui;
        const l =
          typeof bookingLink === "string"
            ? { name: `${name} buchen`, url: bookingLink }
            : bookingLink;
        links.push(l);
      };

      addLink(this.t.transport);
      addLink(firstMile);
      if (lastMile?.ui.bookingLink !== firstMile?.ui.bookingLink)
        addLink(lastMile);
      return links;
    },
    // warnining messages returned by backend related to the route
    warningMessage() {
      return this.t.routeWarnings.join(". ");
    },
    description() {
      let desc = [];
      const { firstMile, lastMile, ui, type, routing } = this.t.transport;
      if (firstMile?.ui.description) desc.push(firstMile.ui.description);
      if (lastMile?.ui.description && lastMile.id !== firstMile.id)
        desc.push(lastMile.ui.description);
      if (ui.description) desc.push(ui.description);

      return desc.join("<br/>");
    },
    reservationPendlerportalURL() {
      if (!this.reservationToolHost) return;
      // const fromLegs = this.t.fromDestination.legs;
      // const toLegs = this.t.toDestination.legs;
      // const distance = toLegs.reduce((dist, leg) => dist + leg.distanceKm, 0);
      // const arrival = toLegs[toLegs.length - 1].arrival;
      // const departure = fromLegs[0].departure;
      // const location = fromLegs[0].geometry.coordinates[0].join(",");
      // const q = toQueryString({ location, distance, arrival, departure });
      return `${this.reservationToolHost}`;
    },
    reservationToolURL() {
      if (!this.reservationToolHost) return;
      const fromLegs = this.t.fromDestination.legs;
      const toLegs = this.t.toDestination.legs;
      const distance = toLegs.reduce((dist, leg) => dist + leg.distanceKm, 0);
      const arrival = toLegs[toLegs.length - 1].arrival;
      const departure = fromLegs[0].departure;
      const location = fromLegs[0].geometry.coordinates[0].join(",");
      const q = toQueryString({ location, distance, arrival, departure });
      return `${this.reservationToolHost}/home?${q}`;
    },

    clever2getherURL() {
      if (!this.rideshareHost) return;
      return `${this.rideshareHost}`;
    },
  },

  methods: {
    generateBahnBookingLinks() {
      let linksToGenerate = [];
      let dbLegs = this.t.toDestination.legs.filter(
        (leg) => leg.verbundNameShort === "db" && leg.mode !== "foot"
      );
      if (dbLegs.length === 0) {
        return [];
      }

      const from = dbLegs[0].nameOrigin;
      const to = dbLegs[dbLegs.length - 1].nameDest;
      const idFrom = dbLegs[0].idOrigin;
      const idTo = dbLegs[dbLegs.length - 1].idDest;
      // reduce from departure time half hour so the relevant connection is shown due to the time buffer
      const halfHourMillisec = 1000 * 60 * 30;

      let departureTimeJourneyThere = new Date(
        Date.parse(dbLegs[0].departure) - halfHourMillisec
      ).toISOString();

      let fromReturn,
        toReturn,
        idFromReturn,
        idToReturn,
        departureTimeJourneyBack;
      if (this.t.fromDestination?.legs) {
        let dbLegsReturn = this.t.fromDestination?.legs.filter(
          (leg) => leg.verbundNameShort === "db" && leg.mode !== "foot"
        );
        if (!dbLegsReturn || dbLegsReturn.length === 0) {
          return;
        }

        fromReturn = dbLegsReturn[0].nameOrigin;
        toReturn = dbLegsReturn[dbLegsReturn.length - 1].nameDest;
        idFromReturn = dbLegsReturn[0].idOrigin;
        idToReturn = dbLegsReturn[dbLegsReturn.length - 1].idDest;

        departureTimeJourneyBack = new Date(
          Date.parse(dbLegsReturn[0].departure) - halfHourMillisec
        ).toISOString();
      }
      let urlFull, urlThere, urlBack;
      /* if its a two way journey and the stations for journey there and back are the same then only one 
      link for both directions is needed*/
      if (fromReturn && toReturn && to === fromReturn && from === toReturn) {
        let params = new URLSearchParams({
          so: from,
          zo: to,
          soid: `L=${idFrom}`,
          zoid: `L=${idTo}`,
          hd: departureTimeJourneyThere,
          rd: departureTimeJourneyBack,
        }).toString();
        urlFull = `https://www.bahn.de/buchung/fahrplan/suche#sts=true&${params}`;
        linksToGenerate.push({
          name: "Bahn buchen (Hin-/Rückfahrt)",
          url: urlFull,
        });
      } else {
        let paramsThere = new URLSearchParams({
          so: from,
          zo: to,
          soid: `L=${idFrom}`,
          zoid: `L=${idTo}`,
          hd: departureTimeJourneyThere,
        }).toString();
        urlThere = `https://www.bahn.de/buchung/fahrplan/suche#sts=true&${paramsThere}`;
        linksToGenerate.push({ name: "Bahn buchen (Hinfahrt)", url: urlThere });

        if (fromReturn && toReturn) {
          let paramsBack = new URLSearchParams({
            so: fromReturn,
            zo: toReturn,
            soid: `L=${idFromReturn}`,
            zoid: `L=${idToReturn}`,
            hd: departureTimeJourneyBack,
          }).toString();
          urlBack = `https://www.bahn.de/buchung/fahrplan/suche#sts=true&${paramsBack}`;

          linksToGenerate.push({
            name: "Bahn buchen (Rückfahrt)",
            url: urlBack,
          });
        }
      }

      return linksToGenerate;
    },
    updateDirection(val) {
      this.direction = val;
    },
    getProviderIconPath(t) {
      let icon = "";
      try {
        icon = t.transport?.ui?.providerIcon
          ? t.transport?.ui?.providerIcon
          : t.transport?.lastMile?.ui?.providerIcon
          ? t.transport?.lastMile?.ui?.providerIcon
          : t.transport?.firstMile?.ui?.providerIcon
          ? t.transport?.firstMile?.ui?.providerIcon
          : undefined;
      } catch (e) {
        return "";
      }
      return icon ? `./provider_logos/${icon}.png` : icon;
    },
    getProviderName(t) {
      const providerName = t.transport.ui?.providerName
        ? t.transport.ui.providerName
        : t.transport.lastMile?.ui?.providerName
        ? t.transport.lastMile?.ui?.providerName
        : t.transport?.firstMile?.ui?.providerName
        ? t.transport?.firstMile?.ui?.providerName
        : undefined;
      return providerName;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
      if (this.expanded && this.$vuetify.breakpoint.mobile)
        this.$vuetify.goTo(this, { offset: 16 });
    },

    computeAverageMetricValue(name, t) {
      let value = t.metrics[name];
      if (!this.onlyJourneyThere && name !== "cost") {
        value /= 2;
      } else if (name === "cost") {
        if (t.metrics.isCostJourneyThereComplete && this.onlyJourneyThere) {
          return t.metrics.costJourneyThere;
        } else if (
          !t.metrics.isCostJourneyThereComplete &&
          !t.metrics.isCostJourneyBackComplete &&
          !this.onlyJourneyThere
        ) {
          this.priceHint =
            "Ticketpreis für Hin- und \nRückfahrt ist unvollständig";
          return t.metrics.costJourneyThere >= t.metrics.costJourneyBack
            ? t.metrics.costJourneyThere
            : t.metrics.costJourneyBack;
        } else if (
          /* if journey there cost is incomplete 
        AND it's journey there and back and  
        AND journey back is complete 
        THEN return back journey back price*/
          !t.metrics.isCostJourneyThereComplete &&
          t.metrics.isCostJourneyBackComplete &&
          !this.onlyJourneyThere
        ) {
          this.priceHint = "Ticketpreis für Hinfahrt\nist unvollständig";
          return t.metrics.costJourneyBack;
        } else if (
          /* if journey there cost is incomplete 
        AND it's ONLY journey there and  
        THEN return back journey there price*/
          !t.metrics.isCostJourneyThereComplete &&
          this.onlyJourneyThere
        ) {
          this.priceHint = "Ticketpreis für Hinfahrt\nist unvollständig";
          return t.metrics.costJourneyThere;
        } else if (
          t.metrics.isCostJourneyThereComplete &&
          !t.metrics.isCostJourneyBackComplete &&
          !this.onlyJourneyThere
        ) {
          this.priceHint = "Ticketpreis für Rückfahrt\nist unvollständig";
          return t.metrics.costJourneyThere;
        } else {
          const totalCost =
            t.metrics.costJourneyThere + t.metrics.costJourneyBack;
          const averageCost = totalCost / 2;
          return averageCost;
        }
      }
      return value;
    },
    formatMetric(name, t) {
      //value, transport) {
      let value = this.computeAverageMetricValue(name, t);
      let transport = t.transport;
      // if not only journey there is requested, then divide the price by 2

      switch (name) {
        case "cost":
          return `${formatNumberToLocaleAnd2Fixed(value / 100)} €`;
        case "time":
          return formatDuration(value);
        case "co2":
          return formatCo2ByValue(value) + " CO₂";
        case "sport":
          const mode1 = transport.routing.mode;
          const mode2 = transport.firstMile?.routing.mode;
          const sportSuffix = [mode1, mode2].includes("bike")
            ? "Tritte"
            : "Schritte";
          let processedValue = [mode1, mode2].includes("escooter")
            ? 0
            : Math.round(value);
          return `${processedValue} ${sportSuffix}`;
      }
    },
    formatMetricIcon(m, transport) {
      // if not only journey there is requested, then divide the price by 2
      if (m.id == "sport") {
        const mode1 = transport.routing.mode;
        const mode2 = transport.firstMile?.routing.mode;
        const materialIcon = [mode1, mode2].includes("bike")
          ? "directions_bike"
          : "directions_run";
        return materialIcon;
      } else {
        return m.icon;
      }
    },

    updateMap(geom) {
      // geom is a single element in the array of routes
      // let geomAdapted;
      // if (this.direction == 0) {
      //   geomAdapted = geom;
      // } else {
      //   geomAdapted = {...geom, geometries: geom.geometries.reverse() };
      // }
      this.geojsonRoutes = [geom];
    },
  },
});
</script>

<style>
.full-width {
  width: 100%;
}

.hovercard {
  cursor: pointer;
  transition: filter 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.15));
}
.hovercard:hover,
.hovercard.expanded {
  /* NOTE: there is a chrome performance regression with box-shadows,
           so we reimplement .v-card--hover with drop-shadow filter */
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.4));
}
.hovercard .details {
  cursor: default;
}

@media print {
  .transport-name {
    min-width: 16em;
  }
}
</style>
