/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.66
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import OepvTicketMode from './OepvTicketMode';
import RoutingSettings from './RoutingSettings';
import UserMetricsSettings from './UserMetricsSettings';
import UserProfileSettings from './UserProfileSettings';
import VehicleSpec from './VehicleSpec';

/**
 * The SettingsTotal model module.
 * @module model/SettingsTotal
 * @version 0.0.2
 */
export default class SettingsTotal {
  /**
   * Constructs a new <code>SettingsTotal</code>.
   * @alias module:model/SettingsTotal
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SettingsTotal</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SettingsTotal} obj Optional instance to populate.
   * @return {module:model/SettingsTotal} The populated <code>SettingsTotal</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SettingsTotal();
      if (data.hasOwnProperty('tickets'))
        obj.tickets = ApiClient.convertToType(data['tickets'], [OepvTicketMode]);
      if (data.hasOwnProperty('vehiclesSpec'))
        obj.vehiclesSpec = ApiClient.convertToType(data['vehiclesSpec'], [VehicleSpec]);
      if (data.hasOwnProperty('userMetricsSettings'))
        obj.userMetricsSettings = ApiClient.convertToType(data['userMetricsSettings'], [UserMetricsSettings]);
      if (data.hasOwnProperty('routingsSettings'))
        obj.routingsSettings = ApiClient.convertToType(data['routingsSettings'], [RoutingSettings]);
      if (data.hasOwnProperty('userProfileSettings'))
        obj.userProfileSettings = UserProfileSettings.constructFromObject(data['userProfileSettings']);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/OepvTicketMode>} tickets
 */
SettingsTotal.prototype.tickets = undefined;

/**
 * @member {Array.<module:model/VehicleSpec>} vehiclesSpec
 */
SettingsTotal.prototype.vehiclesSpec = undefined;

/**
 * @member {Array.<module:model/UserMetricsSettings>} userMetricsSettings
 */
SettingsTotal.prototype.userMetricsSettings = undefined;

/**
 * @member {Array.<module:model/RoutingSettings>} routingsSettings
 */
SettingsTotal.prototype.routingsSettings = undefined;

/**
 * @member {module:model/UserProfileSettings} userProfileSettings
 */
SettingsTotal.prototype.userProfileSettings = undefined;

