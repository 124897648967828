/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.66
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import RouteTotal from './RouteTotal';

/**
 * The RouteResponseWrapper model module.
 * @module model/RouteResponseWrapper
 * @version 0.0.2
 */
export default class RouteResponseWrapper {
  /**
   * Constructs a new <code>RouteResponseWrapper</code>.
   * @alias module:model/RouteResponseWrapper
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RouteResponseWrapper</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RouteResponseWrapper} obj Optional instance to populate.
   * @return {module:model/RouteResponseWrapper} The populated <code>RouteResponseWrapper</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RouteResponseWrapper();
      if (data.hasOwnProperty('data'))
        obj.data = RouteTotal.constructFromObject(data['data']);
    }
    return obj;
  }
}

/**
 * @member {module:model/RouteTotal} data
 */
RouteResponseWrapper.prototype.data = undefined;

